

// -----------------------------------------------------------------------------------------*/
// Surcharge variables BOOTSTRAP V5.1 ------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
@import "theme/scss/_variables-bootstrap-v5.1.scss";


// -----------------------------------------------------------------------------------------*/
// Librairie BOOTSTRAP V5.1 ----------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
@import "theme/scss/bootstrap.scss";


// -----------------------------------------------------------------------------------------*/
// FONTS -----------------------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
@import "theme/scss/_fonts.scss";


// -----------------------------------------------------------------------------------------*/
// PROJET ----------------------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/

$app-breakpoint:            md;
$header-height-mobile:       75px;
$header-height-desktop:      90px;
$timeline-height-mobile:     60px;
$timeline-height-desktop:    100px;

@import "theme/scss/_styles.scss";
@import "theme/scss/_main.scss";
@import "theme/scss/_header.scss";
@import "theme/scss/_timeline.scss";





