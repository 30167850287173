
// HACK COOKIE
div .hMxmxV.hMxmxV.hMxmxV.hMxmxV.hMxmxV.hMxmxV { 
    left: 0px !important;
}

// -----------------------------------------------------------------------------------------*/
// TIMELINE FOOTER -------------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
.timeline { 
    background-color: #ececec;
    text-align: center;
    min-height: $timeline-height-mobile;
    padding-top: 10px;
    padding-left: 60px;
    @extend .shadow-lg;
    position: fixed;
    z-index: 5;
    inset: auto 0 0 0;
    a[class*="timeline-"] { 
        margin: 0 20px;
    }
    @include media-breakpoint-up($app-breakpoint) {
        min-height: $timeline-height-desktop;
    }

    @include media-breakpoint-up(sm) {
        padding-left: 80px;
    }
}

.timeline-arrow {
    font-size: 2rem;
    color: $gray-light;
    transition: all .25s ease-in-out;
    cursor: pointer;
    line-height: 1.1;
    &:hover { 
        color: $primary;
    }

    @include media-breakpoint-up($app-breakpoint) {
        font-size: 3rem;
    }
}

.timeline-link { 
    transition: all .25s ease-in-out;
    font-weight: bold;
    text-decoration: none;
    line-height: 1.2;
    font-size: 1rem;
    display: none;
    color: $gray-700;
    width: 50%;

    &:nth-last-child(4),
    &:nth-last-child(3),
    &:nth-last-child(2),
    &:last-child { 
        display: block;
    }
    span {
        font-size: 2rem;
    }
}

.timeline-active { 
    color: $primary;
}

.timeline-links { 
    font-size: 0.7rem;
    margin-bottom: 6px;
    text-align: center;
    a { 
        color: $gray-700;
    }
    @include media-breakpoint-up($app-breakpoint) {
        text-align: right;
        margin-top: 10px;
    }
}