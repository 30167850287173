

// -----------------------------------------------------------------------------------------*/
// HEADER ----------------------------------------------------------------------------------*/
// -----------------------------------------------------------------------------------------*/
.header { 
    background-color: #231f1f;
    border-bottom: 2px solid $primary;
    text-align: center;
    padding-top: 14px;
    height: $header-height-mobile;
    @extend .shadow;
    position: fixed;
    z-index: 5;
    inset: 0 0 auto 0;

    @include media-breakpoint-up($app-breakpoint) {
        height: $header-height-desktop;
    }
}
.logo {
    max-height: 60px;
    @include media-breakpoint-up($app-breakpoint) {
        max-height: 76px;
    }
}