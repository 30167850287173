

.main-app-container { 
    .btn-check { 
        &:checked {
            + .btn-outline-secondary { 
                color: $primary;
                background-color: $white;
                border-color: $primary;
                box-shadow: 0 0 0 0.25rem rgba($primary, 0.5);
            }
        }
    }
}



// Input adresse postale
div.css-b62m3t-container {
    > div {
        min-height: 60px;
        border-width: 2px;
    }
}
div.css-1pahdxg-control {
    border-width: 2px;
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
    &:hover { 
        border-color: $input-focus-border-color;
    }
}
div.css-319lph-ValueContainer {
    padding: 1rem 0.75rem;
}



// BTN COLOR
.btn-myline { 
    @extend .btn-outline-primary;
}



// Résultat simulateur
.box-resultats { 
    text-align: center;
    border-radius: 1.6rem;
    border: 2px solid $green;
    color: darken($green, 10%);
    background-color: $white;
    font-size: 2rem;
    max-width: 320px;
    padding: 30px 20px 34px;
    margin: 0 auto;
    position: relative;
    &:after {
        position: absolute;
        z-index: -1;
        content: "";
        display: block;
        inset: 15px 15px -10px 15px;
        background-color: rgba($green,0.5);
        filter: blur(20px);
    }
}
