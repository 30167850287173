@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon/icomoon.eot");
    src: url("../fonts/icomoon/icomoon.eot?#iefix") format("embedded-opentype"),
      url("../fonts/icomoon/icomoon.ttf") format("truetype"),
      url("../fonts/icomoon/icomoon.woff") format("woff"),
      url("../fonts/icomoon/icomoon.svg?#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  .icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  

  .icon-appartement-1:before {
    content: "\e600";
  }
  .icon-appel-1:before {
    content: "\e601";
  }
  .icon-code-1:before {
    content: "\e602";
  }
  .icon-construction-1:before {
    content: "\e603";
  }
  .icon-contact-1:before {
    content: "\e604";
  }
  .icon-cuisine-1:before {
    content: "\e605";
  }
  .icon-environnement-1:before {
    content: "\e606";
  }
  .icon-environnement-2:before {
    content: "\e607";
  }
  .icon-inconvenients-1:before {
    content: "\e608";
  }
  .icon-inconvenients-2:before {
    content: "\e609";
  }
  .icon-location-1:before {
    content: "\e60a";
  }
  .icon-maison-1:before {
    content: "\e60b";
  }
  .icon-mitoyennete-1:before {
    content: "\e60c";
  }
  .icon-nb-pieces-1:before {
    content: "\e60d";
  }
  .icon-nb-pieces-2:before {
    content: "\e60e";
  }
  .icon-niveaux-appart-1:before {
    content: "\e60f";
  }
  .icon-piscine-1:before {
    content: "\e610";
  }
  .icon-promoteur-1:before {
    content: "\e611";
  }
  .icon-proprietaire-1:before {
    content: "\e612";
  }
  .icon-reseaux-1:before {
    content: "\e613";
  }
  .icon-resultat-1:before {
    content: "\e614";
  }
  .icon-resultat-mail-1:before {
    content: "\e615";
  }
  .icon-route-1:before {
    content: "\e616";
  }
  .icon-securite-1:before {
    content: "\e617";
  }
  .icon-sms-1:before {
    content: "\e618";
  }
  .icon-surface-1:before {
    content: "\e619";
  }
  .icon-surface-2:before {
    content: "\e61a";
  }
  .icon-surface-3:before {
    content: "\e61b";
  }
  .icon-terrain-1:before {
    content: "\e61c";
  }
  .icon-terrassement-1:before {
    content: "\e61d";
  }
  .icon-type-terrain-1:before {
    content: "\e61e";
  }
  .icon-valeur-bien-1:before {
    content: "\e61f";
  }
  .icon-ville-1:before {
    content: "\e620";
  }
  .icon-zone-rouge-1:before {
    content: "\e621";
  }
  .icon-zone-rouge-2:before {
    content: "\e622";
  }
  .icon-agent-1:before {
    content: "\e623";
  }
  .icon-annee-construction-1:before {
    content: "\e624";
  }
  